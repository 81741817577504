import $ from "jquery";

const unfoldPlanCourses = () => {

  const planSections = document.querySelectorAll("#plan-section");
  Array.from(planSections).forEach((section) => {
    section.addEventListener("click",  function (event) {
      if (event.target.tagName == "H2" && section.classList.contains("toggle-off")) {
        section.classList.replace('toggle-off', 'toggle-on')
        Array.from(section.children).forEach((exercice) => {
          if (exercice.classList.contains('toggle-name-off')) {
            exercice.classList.replace('toggle-name-off', 'toggle-name-on')
          }    
        }) 
      } else if (event.target.tagName == "H2" && section.classList.contains("toggle-on")) {
        section.classList.replace('toggle-on', 'toggle-off')
        Array.from(section.children).forEach((exercice) => {
          if (exercice.classList.contains('toggle-name-on')) {
            exercice.classList.replace('toggle-name-on', 'toggle-name-off')
          }    
        })
      }
    });
  });

  const exercices = document.querySelectorAll("#exercice");
  Array.from(exercices).forEach((exercice) => {
    exercice.addEventListener("click",  function (event) {
      if (event.target.tagName == "H3" && exercice.classList.contains("toggle-off")) {
        exercice.classList.replace('toggle-off', 'toggle-on')
        Array.from(exercice.children).forEach((description) => {
          if (description.classList.contains('toggle-description-off')) {
            description.classList.replace('toggle-description-off', 'toggle-description-on')
          }    
        }) 
      } else if (event.target.tagName == "H3" && exercice.classList.contains("toggle-on")) {
        exercice.classList.replace('toggle-on', 'toggle-off')
        Array.from(exercice.children).forEach((description) => {
          if (description.classList.contains('toggle-description-on')) {
            description.classList.replace('toggle-description-on', 'toggle-description-off')
          }    
        }) 
      }
  });
});
};
export { unfoldPlanCourses };
